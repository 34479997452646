<template lang="pug">
  .shops-table
    table
      thead
        tr
          th.shop-code.sortable(@click="handleSorting('code')")
            span {{ $t("company_system.shop_settings.table.shop_code") }}
            FaIcon.icon(:icon="sortingIcon('code')")
          th.shop-name.sortable(@click="handleSorting('name')")
            span {{ $t("company_system.shop_settings.table.shop_name") }}
            FaIcon.icon(:icon="sortingIcon('name')")
          th.inventory-group.sortable(@click="handleSorting('inventory_group_name')")
            span {{ $t("company_system.shop_settings.table.inventory_group") }}
            FaIcon.icon(:icon="sortingIcon('inventory_group_name')")
          th.address.sortable(@click="handleSorting('address')")
            span {{ $t("company_system.shop_settings.table.address") }}
            FaIcon.icon(:icon="sortingIcon('address')")
          th.status.sortable(@click="handleSorting('released')")
            span {{ $t("company_system.shop_settings.table.status") }}
            FaIcon.icon(:icon="sortingIcon('released')")
          th.updated-at.sortable(@click="handleSorting('updated_at')")
            span {{ $t("company_system.shop_settings.table.updated_at") }}
            FaIcon.icon(:icon="sortingIcon('updated_at')")
          th.actions
      tbody
        ShopRow(
          v-for="shop in shops"
          :key="shop.id"
          :shop="shop"
          :has-edit-permission="hasEditPermission"
          @select-shop="$emit('select-shop', $event)"
          @remove-shop="$emit('remove-shop', $event)"
        )
</template>

<script>
  // mixins
  import withSorting from "@/mixins/withSorting"

  export default {
    mixins: [withSorting],

    props: {
      hasEditPermission: {
        type: Boolean,
        default: true
      },
      shops: {
        type: Array,
        default: () => new Array()
      },
      sortingData: Object
    },

    components: {
      ShopRow: () => import("./ShopRow")
    }
  }
</script>

<style lang="sass">
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/mixins/common.sass"

  .shops-table
    +listing-container(121px)

    table
      +custom-table
      +matchings-table-header-sticky
      table-layout: fixed
      overflow-wrap: break-word

      thead
        th
          &.shop-code
            width: 10%

          &.shop-name
            width: 20%

          &.inventory-group
            width: 15%

          &.address
            width: 30%

          &.status
            width: 7%

          &.updated-at
            width: 15%

          &.actions
            width: 5%

      tbody
        tr
          cursor: pointer

          &:hover
            box-shadow: 0 0 3px 0 $default-gray
</style>
